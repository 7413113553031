import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyC0k_UXAVxEXYNfGmw57c_L-_c1bvaqTIo",
    authDomain: "sheepchat-1d3da.firebaseapp.com",
    databaseURL: "https://sheepchat-1d3da-default-rtdb.firebaseio.com",
    projectId: "sheepchat-1d3da",
    storageBucket: "sheepchat-1d3da.appspot.com",
    messagingSenderId: "143811813960",
    appId: "1:143811813960:web:29d8aaa56987819d61ee7d"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();